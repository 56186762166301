<template>
  <div class="entry">
    <b-container fluid class="entry-information">
      <b-row class="entry-row" align-h="center">
        <b-col sm="3" class="top-mode" v-for="(card, i) in cardData" :key="i">
          <b-row class="mode-text">
            <span class="text">{{ card.mode }}模式</span>
          </b-row>
          <b-row align-h="center">
            <b-col>
              答题
              <span class="text-data">{{ card.totalQuestions }}</span>
              道 正确率
              <span class="text-data"
                >{{ card.correctPercentage | turnPercentage }}%</span
              >
            </b-col>
          </b-row>
        </b-col>
        <!--        <b-col sm="3" class="top-mode">-->
        <!--          <b-row class="mode-text">-->
        <!--            <span class="text">自由模式</span>-->
        <!--          </b-row>-->
        <!--          <b-row align-h="center">-->
        <!--            <b-col>-->
        <!--              答题-->
        <!--              <span class="text-data">72</span>-->
        <!--              道 正确率-->
        <!--              <span class="text-data">53%</span>-->
        <!--            </b-col>-->
        <!--          </b-row>-->
        <!--        </b-col>-->
        <!--        <b-col sm="3" class="top-mode">-->
        <!--          <b-row class="mode-text">-->
        <!--            <span class="text">用时偏差</span>-->
        <!--          </b-row>-->
        <!--          <b-row align-h="center">-->
        <!--            <b-col>-->
        <!--              用时-->
        <!--              <span class="text-data">0.2/4.4</span>-->
        <!--              小时 偏差-->
        <!--              <span class="text-data">-96%</span>-->
        <!--            </b-col>-->
        <!--          </b-row>-->
        <!--        </b-col>-->
      </b-row>
      <b-row>
        <b-col>
          <el-tabs
            class="handle-class"
            v-model="activeName"
            @tab-click="handleClick"
          >
            <el-tab-pane label="战备体检" name="1"></el-tab-pane>
            <el-tab-pane label="扫雷尖兵" name="2"></el-tab-pane>
            <el-tab-pane label="沙漠行动" name="4"></el-tab-pane>
            <el-tab-pane label="抢滩登陆" name="7"></el-tab-pane>
            <el-tab-pane disabled label="收藏试题" name="collect">
              <span slot="label" class="tab-collect">
                <i class="iconfont icon-icon-"></i> 收藏试题
              </span>
            </el-tab-pane>
            <el-tab-pane disabled label="作业记录" name="log">
              <span slot="label" class="">
                <i class="iconfont icon-wj-mxjl"></i>作业记录
              </span>
            </el-tab-pane>
          </el-tabs>
        </b-col>
      </b-row>
      <el-row class="checkbox-wrap">
        <el-col class="checkbox-wrap-content" :lg="10" :md="24">
          <el-checkbox
            v-model="checkAll"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <div>
            <span class="text-title">{{ topOptions[0].label }}：</span>
            <el-checkbox-group
              v-model="checkboxGroup1"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox
                v-for="item in topOptions[0].options"
                :key="item.label"
                :label="item"
                >{{ item.label }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <div>
            <!-- 答题 -->
            <span class="text-title">{{ topOptions[1].label }}：</span>
            <el-checkbox-group
              v-model="checkboxGroup2"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox
                v-for="item in topOptions[1].options"
                :key="item.label"
                :label="item"
                >{{ item.label }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </el-col>
        <el-col
          class="checkbox-wrap-content"
          style="justify-content: flex-end; padding-right: 20px"
          :lg="10"
          :md="24"
        >
          <!-- <div>
            <span class="text-title">{{ topOptions[2].label }}：</span>
            <el-checkbox-group
              v-model="checkboxGroup3"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox
                v-for="item in topOptions[2].options"
                :key="item.label"
                :label="item"
                >{{ item.label }}</el-checkbox
              >
            </el-checkbox-group>
          </div> -->
          <div>
            <div class="time">
              <span class="text-title">题型：</span>
              <el-select
                v-model="questionType"
                placeholder="请选择"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="(item, i) in questionClass"
                  :key="i"
                  :label="item.label"
                  :value="i"
                  @click.native="questionTypeChange(item.value)"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div>
            <div class="time">
              <span class="text-title">时间：</span>
              <el-select
                v-model="startTime"
                placeholder="请选择"
                :popper-append-to-body="false"
                @change="changeSelect"
              >
                <el-option
                  v-for="(item, i) in options"
                  :key="i"
                  :label="item.label"
                  :value="i"
                >
                </el-option>
              </el-select>
              <button class="search btn" @click="search">搜索</button>
            </div>
          </div>
        </el-col>
        <el-col class="rework" :lg="4">
          <button class="btn redo" @click="redo()">错题重做</button>
        </el-col>
      </el-row>
      <el-table
        ref="multipleTable"
        :data="cuurentData"
        tooltip-effect="dark"
        style="width: 100%; min-height: 39rem"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
        @row-click="toLog"
      >
        <el-table-column label="时间" align="center" min-width="7%">
          <template slot-scope="scope">{{
            scope.row.creationTime | dateFormat
          }}</template>
        </el-table-column>
        <el-table-column
          prop="classA"
          label="位置"
          align="center"
          min-width="7%"
        >
        </el-table-column>
        <el-table-column
          prop="classB"
          label="知识模块"
          align="center"
          show-overflow-tooltip
          min-width="7%"
        >
        </el-table-column>
        <el-table-column
          prop="questionStatement"
          label="作答试题"
          align="center"
          show-overflow-tooltip
          min-width="40%"
        >
          <template slot-scope="{ row }">
            <div v-html="row.questionStatement"></div>
          </template>
        </el-table-column>
        <el-table-column
          prop="questionClass"
          label="题型"
          align="center"
          show-overflow-tooltip
          min-width="9%"
        >
        </el-table-column>
        <el-table-column
          prop="difficulty"
          label="难度"
          align="center"
          min-width="8%"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="questionStatement"
          label="答题"
          align="center"
          min-width="6%"
        >
          <template slot-scope="{ row }">
            <div
              style="margin: 0"
              class="status"
              :class="changeColor(row.fullScore, row.score)"
            ></div>
          </template>
        </el-table-column>
        <el-table-column
          prop="remarks"
          label="点评"
          align="center"
          min-width="6%"
          show-overflow-tooltip
        >
          <span
            slot-scope="{ row }"
            v-if="row.remarks"
            class="iconfont iconxiaoxi"
          />
        </el-table-column>
        <el-table-column
          :selectable="checkboxSelect"
          type="selection"
          align="center"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="address"
          label="重做结果"
          align="center"
          min-width="7%"
        >
          <template slot-scope="{ row }">
            <div
              style="margin: 0"
              class="status"
              :class="
                secondTimeChangeColor(row.fullScore, row.secondAnswerScore)
              "
            ></div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="address"
          label="错题重做"
          align="center"
          width="120"
        >
          <template slot-scope="{ row }">
            <el-button type="primary" round @click.stop="redo([row])">
              重做
            </el-button></template
          >
        </el-table-column> -->
      </el-table>
      <div class="bottom-content">
        <div class="status-list">
          <div>
            <div class="status green"></div>
            正确答题
          </div>
          <div>
            <div class="status red"></div>
            错误答题，或提前查看了标准答案
          </div>
          <div>
            <div class="status yellow"></div>
            部分正确，或使用了两次答题机会
          </div>
        </div>
        <!-- total.sync 总数     -->
        <el-pagination
          :current-page.sync="pageIndex"
          :page-sizes="[10, 20, 30, 40]"
          :page-size.sync="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total.sync="tableData.length"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
          <!-- :hide-on-single-page="data.length < pageSize" -->
        </el-pagination>
        
      </div>
      <GlobalPopup
        :popupsTitle="`请勾选至少一个需要重做的试题，再点击错题重做哦~`"
        :popupsbuttomB="`知道了~`"
        @OKbutton="
          () => {
            modal1 = false;
          }
        "
        :isShow="modal1"
      ></GlobalPopup>
    </b-container>
  </div>
</template>

<script>
import GlobalPopup from "@/components/global-popup/global-popup-modal";
import {
  GetStudentAnswerRecords,
  GetStudentPerformanceStats,
} from "@/api/english-entry";
import moment from "moment";

const C = [
  {
    label: "模式",
    value: "mode",
    options: [
      {
        value: false,
        label: "闯关",
        key: "mode",
      },
      {
        value: true,
        label: "自由",
        key: "mode",
      },
    ],
  },
  {
    label: "答题",
    value: "possibleScore",
    options: [
      {
        value: 1,
        label: "正确答题",
        key: "status",
      },
      // {
      //   value:  '5',
      //   key: 'status',
      //   label: '部分正确'
      // },
      {
        value: 0,
        label: "错误答题",
        key: "status",
      },
    ],
  },
  {
    label: `难度`,
    value: `difficulty`,
    options: [
      {
        value: `7`,
        label: "七年级",
        key: `difficulty`,
      },
      {
        value: `8.1`,
        label: "八年级上",
        key: `difficulty`,
      },
      {
        value: `8`,
        label: "八年级",
        key: `difficulty`,
      },
      {
        value: `8.2`,
        label: "八年级下",
        key: `difficulty`,
      },
      {
        value: `9`,
        label: "九年级",
        key: `difficulty`,
      },
    ],
  },
];
let dataOptions = [
  {
    value: moment().startOf("day").subtract(1, "weeks"),
    label: "近一周",
  },
  {
    value: moment().startOf("day").subtract(2, "weeks"),
    label: "近两周",
  },
  {
    value: moment().startOf("day").subtract(1, "months"),
    label: "近一月",
  },
  {
    value: moment().startOf("day").subtract(2, "months"),
    label: "近两月",
  },
  {
    value: moment().startOf("day").subtract(24, "months"),
    label: "全部",
  },
];
let startQuestionClass = [
  { value: 3, label: "完形填空" },
  { value: 4, label: "补全对话" },
  { value: 5, label: "阅读理解" },
  { value: 6, label: "语法填空" },
  { value: 7, label: "阅读理解七选五" },
  { value: 19, label: "情景运用" },
  { value: 20, label: "小作文" },
  { value: 0, label: "全部" },
];
let smallQuestionClass = [
  { value: 2, label: "选择填空" },
  { value: 21, label: "词汇语法选择题" },
  { value: 22, label: "词汇语法填空题" },
  { value: 0, label: "全部" },
];
let bigQuestionClass = [
  { value: 3, label: "完形填空" },
  { value: 4, label: "补全对话" },
  { value: 5, label: "阅读理解" },
  { value: 6, label: "语法填空" },
  { value: 7, label: "阅读理解七选五" },
  { value: 19, label: "情景运用" },
  { value: 20, label: "小作文" },
  { value: 24, label: "写作训练" },
  { value: 0, label: "全部" },
];
let mockQuestionClass = [
  { value: 2, label: "选择填空" },
  { value: 3, label: "完形填空" },
  { value: 4, label: "补全对话" },
  { value: 5, label: "阅读理解" },
  { value: 6, label: "语法填空" },
  { value: 7, label: "阅读理解七选五" },
  { value: 19, label: "情景运用" },
  { value: 20, label: "小作文" },
  { value: 0, label: "全部" },
];
let otherQuestionClass = [
  { value: 2, label: "选择填空" },
  { value: 3, label: "完形填空" },
  { value: 4, label: "补全对话" },
  { value: 5, label: "阅读理解" },
  { value: 6, label: "语法填空" },
  { value: 7, label: "阅读理解七选五" },
  { value: 19, label: "情景运用" },
  { value: 20, label: "小作文" },
  { value: 21, label: "词汇语法选择题" },
  { value: 22, label: "词汇语法填空题" },
  { value: 24, label: "写作训练" },
  { value: 0, label: "全部" },
];
export default {
  name: "logList",
  data() {
    return {
      activeName: "4",
      topOptions: C,
      checkboxGroup1: C[0].options,
      checkboxGroup2: [C[1].options[1]],
      checkboxGroup3: C[2].options,
      options: dataOptions,
      isIndeterminate: false,
      checkpoint: 0,
      startTime: 4,
      tableData: [],
      multipleSelection: [],
      pageIndex: 1,
      pageSize: 10,
      checkAll: true,
      cardData: [],
      WrongQuestions: [],
      params: {},
      total: 0,
      questionType: 9,
      questionClass: otherQuestionClass,
      modal1: false,
      cuurentData: [],
      questionClassId: "",
    };
  },
  activated() {
    this.getData();
    this.gitHead();
    this.moduleQuestioType();
  },
  mounted() {
    this.getData();
    this.gitHead();
    this.moduleQuestioType();
  },
  components: {
    GlobalPopup,
  },
  watch: {
    tableData: {
      handler(newData) {
        console.log("未处理表格数据", this.tableData);
        if (!newData.length) {
          this.pageIndex = 1;
        }
        this.cuurentData = this.tableData.slice(
          this.pageSize * this.pageIndex - this.pageSize,
          this.pageSize * this.pageIndex
        );
        console.log("已处理表格数据", this.cuurentData);
      },
    },
    pageIndex: {
      handler() {
        this.cuurentData = this.tableData.slice(
          this.pageSize * this.pageIndex - this.pageSize,
          this.pageSize * this.pageIndex
        );
      },
      immediate: true,
      deep: true,
    },
    pageSize: {
      handler() {
        this.cuurentData = this.tableData.slice(
          this.pageSize * this.pageIndex - this.pageSize,
          this.pageSize * this.pageIndex
        );
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    // total() {
    //   return this.cardData.map(item => item.totalQuestions).reduce((card1, card2) => card1 + card2, 0)
    // },
    // currentData() {
    //   return this.tableData.slice(
    //     this.pageSize * this.pageIndex - this.pageSize,
    //     this.pageSize * this.pageIndex
    //   );
    // },
  },
  methods: {
    checkboxSelect(row) {
      debugger;
      console.log("全选", row);
      if (
        (row.fullScore !== row.score && row.secondAnswerScore == null) ||
        (row.secondAnswerScore !== row.fullScore &&
          row.secondAnswerScore !== null)
      ) {
        return true;
      } else {
        return false;
      }
    },
    gitHead() {
      GetStudentPerformanceStats({ moduleId: this.currentModuleId }).then(
        (res) => {
          this.cardData = res.data.result;
          // if (this.cardData.length === 1) {
          //   this.cardData.push({
          //     correctPercentage: 0,
          //     mode: "自由",
          //     totalQuestions: 0,
          //   });
          // }
        }
      );
    },
    getData() {
      // debugger;
      this.activeName = "" + this.currentModuleId;
      let isPractice, whichRecord, difficulties;
      isPractice =
        this.checkboxGroup1.length === 2 ? null : this.checkboxGroup1[0].value;
      whichRecord =
        this.checkboxGroup2.length === 2 ? null : this.checkboxGroup2[0].value;
      difficulties =
        this.checkboxGroup3.length === 5
          ? null
          : this.checkboxGroup3.map((item) => item.value);
      // console.log("isPractice", this.checkboxGroup2);

      GetStudentAnswerRecords({
        isPractice,
        whichRecord,
        difficulties,
        test: null,
        moduleId: this.currentModuleId,
        pageSize: this.pageSize,
        page: this.pageIndex - 1,
        startTime: this.options[this.startTime].value.toDate(), // 开始时间，按时间范围查询， 本周，本月，本季或者其他时间维度
        endTime: moment().add(3, "days").startOf("day").toJSON(), // 结束时间
        questionClass: this.questionClassId, // 题型
      }).then((res) => {
        const { records, totalCount } = res.data.result;
        this.total = totalCount;
        this.tableData = records;
        // this.$router.replace({name: 'Entry',query: {
        //     module: this.activeName
        //   }})
      });
    },
    toLog(item) {
      console.log("item", item);
      this.$store.commit("setListOfWrongQuestions", [
        { questionId: item.questionId, stemId: item.stemId },
      ]);
      this.$router.push({
        name: "log",
        query: {
          type: item.questionClassId,
          name: item.classA,
        },
      });
    },
    redo(WrongQuestions = this.WrongQuestions) {
      console.log("WrongQuestions", WrongQuestions);
      if (WrongQuestions.length == 0) {
        this.modal1 = true;
      } else {
        this.$store.commit("setExamId");
        this.$store.commit(
          "setListOfWrongQuestions",
          WrongQuestions.map((item) => ({
            questionId: item.questionId,
            stemId: item.stemId,
          }))
        );
        // this.$store.commit('setredoQuestion', this.listOfWrongQuestions[0])
        this.$router.push({ name: "detail" });
      }
    },
    changeColor(possibleScore, score) {
      // debugger
      if (possibleScore <= score) return "green";
      if (score === 0) return "red";
      return "yellow";
    },
    //  应得分   重做答题分数
    secondTimeChangeColor(possibleScore, secondAnswerScore) {
      // debugger
      if (possibleScore == secondAnswerScore) return "green";
      if (secondAnswerScore === 0) return "red";
      if (
        secondAnswerScore < possibleScore &&
        secondAnswerScore !== 0 &&
        secondAnswerScore !== null
      )
        return "yellow";
      return "";
    },
    handleCurrentChange(index) {
      this.pageIndex = index;
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.pageIndex = 1;
    },
    handleClick(tab) {
      this.pageIndex = 1;
      this.$store.commit("setCurrentModuleId", +tab.name);
      this.questionTypeChange(0);
      this.moduleQuestioType();
      this.getData();
      this.gitHead();
    },
    moduleQuestioType() {
      if (this.currentModuleId == 1) {
        this.questionClass = startQuestionClass;
        this.questionType = 7;
      }
      if (this.currentModuleId == 2) {
        this.questionClass = smallQuestionClass;
        this.questionType = 3;
      }
      if (this.currentModuleId == 4) {
        this.questionClass = bigQuestionClass;
        this.questionType = 8;
      }
      if (this.currentModuleId == 7) {
        this.questionClass = mockQuestionClass;
        this.questionType = 8;
      }
      if (isNaN(this.currentModuleId)) {
        this.questionClass = otherQuestionClass;
        this.questionType = 11;
      }
    },
    //全选按钮
    handleCheckAllChange(val) {
      debugger;
      console.log("val", val);
      this.checkboxGroup3 = val ? this.topOptions[2].options : [];
      this.checkboxGroup2 = val ? this.topOptions[1].options : [];
      this.checkboxGroup1 = val ? this.topOptions[0].options : [];
      this.isIndeterminate = false;
    },
    //筛选按钮
    handleCheckedCitiesChange() {
      if (
        this.checkboxGroup1.length === this.topOptions[0].options &&
        this.checkboxGroup2.length === this.topOptions[1].options &&
        this.checkboxGroup3.length === this.topOptions[2].options
      ) {
        this.checkAll = true;
        this.isIndeterminate - false;
      } else {
        this.checkAll = false;
        this.isIndeterminate = true;
      }
    },
    changeSelect() {},
    questionTypeChange(id) {
      if (id == 5) {
        this.questionClassId = "5,13,14,15,16";
      } else if (id == 0) {
        this.questionClassId = "";
      } else if (id == 6) {
        this.questionClassId = "6,17,18";
      } else {
        this.questionClassId = id;
      }
    },
    search() {
      this.pageIndex = 1;
      this.getData();
    },
    handleSelectionChange(val) {
      this.WrongQuestions = val;
    },
    tableRowClassName({ rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "bg-green";
      }
      return "bg-white";
    },
  },
};
</script>

<style lang="scss" scoped>
.entry {
  width: 100%;
  height: 100%;
  padding: 0 4%;
  .entry-information {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    .el-button.is-round {
      padding: 6px 23px;
    }
  }
  .entry-row {
    padding-top: 1rem;
  }
  .top-mode {
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 1.5rem 0;
    margin: 1rem 2rem;
    position: relative;
  }
  .text-data {
    color: #fecc42;
    font-size: 1.2vw;
    font-weight: bold;
  }
  .mode-text {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 1rem 0;
  }
  .text {
    /*width: 8rem;*/
    color: #fff;
    background-color: #2cb774;
    padding: 0.5rem 1rem;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    position: absolute;
    top: 0;
  }
}

.tab-collect {
  display: inline-block;
  text-align: center;
  // margin-left: 60px;
  // width: 200px;
  box-sizing: border-box;
}

.checkbox-wrap {
  // margin-top: 10px;
  background-color: #f5f5f5;
  display: flex;
  &-content {
    height: 50px;
    div {
      display: flex;
      align-items: center;
      .el-checkbox {
        margin-right: 20px;
        margin-bottom: 0;
        .el-checkbox__label {
          padding-left: 4px;
        }
      }
    }
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  & > div {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  .rework {
    justify-content: end;
  }
  .is-checked {
    margin-bottom: 0;
  }
}
.status-list {
  display: flex;
  flex-wrap: nowrap;
  & > div {
    font-size: 14px;
  }
  /*padding-left 20px*/
}
.status {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 20px;
  border-radius: 50%;
  vertical-align: middle;
}
.red {
  background-color: $bg-red;
}
.yellow {
  background-color: $bg-yellow;
}
.green {
  background-color: $bg-green;
}
.search {
  margin-left: 12px;
  width: 80px;
  height: 30px;

  font-size: 16px;
  line-height: 30px;
}
.redo {
  width: 7rem;
  // height: 2.7rem;
  line-height: 2.7rem;
  font-size: 1.2rem;
  margin-right: 2rem;
  font-weight: bold;
  background-color: #f9a835 !important;
}
.btn {
  padding: 0;
  border: none;
  outline: none;
  background: $bg-green;
  border-radius: 5px;
  color: $text-white;
}
.bottom-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.checkbox-wrap-content {
  & > div {
    padding: 0 1rem;
  }
}
</style>
<style lang="scss">
.el-table__header-wrapper .el-table-column--selection {
  padding-left: 2rem;
}
.el-table__header-wrapper .el-table-column--selection .cell {
  & {
    position: relative;
  }
  & > :before {
    position: absolute;
    top: 0;
    left: -38px;
    content: "全选";
    display: inline-block;
  }
  .el-checkbox {
    margin-bottom: 0;
  }
}
.bg-green {
  background-color: #eef9f4 !important;
  cursor: pointer;
  .is-center {
    padding: 7px 0;
  }
  & > .is-center:nth-child(4) {
    text-align: left;
  }
  .el-checkbox__input.is-disabled .el-checkbox__inner {
    border: none !important;
    background: none !important;
  }
}
.bg-white {
  cursor: pointer;
  & > .is-center:nth-child(4) {
    text-align: left;
  }
  .el-checkbox__input.is-disabled .el-checkbox__inner {
    border: none !important;
    background: none !important;
  }
}
.handle-class {
  .el-tabs__item {
    color: #333;
    font-size: 17px;
  }
  .is-disabled {
    color: rgba(0, 0, 0, 0.3) !important;
  }
}
.checkbox-wrap {
  .el-input__inner {
    height: 30px;
  }
  .el-input__icon {
    line-height: 30px;
  }
}
</style>
