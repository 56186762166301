import { axios } from '@/utils/request'
const api = {
  GetStudentAnswerRecords: '/services/app/EnglishRecordService/GetStudentAnswerRecords',
  GetOneQuestionHistory: '/services/app/EnglishRecordService/GetOneQuestionHistory',
  SaveRedoAnswer: '/services/app/EnglishRecordService/SaveRedoAnswer',
  RedoSummary: '/services/app/EnglishRecordService/RedoSummary',
  SavePrivateNote: '/services/app/QWordsEvaluate/SavePrivateNote',
  GetPrivateNoteForOneQuestion: '/services/app/QWordsEvaluate/GetPrivateNoteForOneQuestion',
  SavaData: '/services/app/Collection/SavaData',
  Update: '/services/app/Collection/Update',
  GetQuestionStateInfo: '/services/app/QWordsEvaluate/GetQuestionStateInfo',
  GetStudentPerformanceStats: '/services/app/EnglishRecordService/GetStudentPerformanceStats',
  CancelCollection: '/services/app/Collection/CancelCollection'

}

export function CancelCollection(parameter) {
  return axios({
    url: api.CancelCollection,
    method: "get",
    params: parameter,
  });
}
export function GetStudentPerformanceStats(parameter) {
  return axios({
    url: api.GetStudentPerformanceStats,
    method: "get",
    params: parameter,
  });
}
export function GetQuestionStateInfo(parameter) {
  return axios({
    url: api.GetQuestionStateInfo,
    method: "get",
    params: parameter,
  });
}
export function Update(parameter) {
  return axios({
    url: api.Update,
    method: "PUT",
    data: parameter,
  });
}
export function SavaData(parameter) {
  return axios({
    url: api.SavaData,
    method: "post",
    data: parameter,
  });
}
export function GetPrivateNoteForOneQuestion(parameter) {
  return axios({
    url: api.GetPrivateNoteForOneQuestion,
    method: "get",
    params: parameter,
  });
}
export function SavePrivateNote(parameter) {
  return axios({
    url: api.SavePrivateNote,
    method: "post",
    data: parameter,
  });
}
export function GetStudentAnswerRecords(parameter) {
  return axios({
    url: api.GetStudentAnswerRecords,
    method: "post",
    data: parameter,
  });
}

export function GetOneQuestionHistory(parameter) {
  return axios({
    url: api.GetOneQuestionHistory,
    method: "get",
    params: parameter,
  });
}
export function SaveRedoAnswer(parameter) {
  return axios({
    url: api.SaveRedoAnswer,
    method: "post",
    data: parameter,
  });
}
export function RedoSummary(parameter) {
  return axios({
    url: api.RedoSummary,
    method: "post",
    params: parameter,
  });
}
